import { Container, Fab, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import {
	Home,
	Calendar,
	Progress,
	ProfileIcon,
	FilledProgress,
	FilledHome,
	FilledCalendar,
	FilledWorkout,
	StartTraining,
	FilledProfile,	
} from '../resources';
import PeopleIcon from '@mui/icons-material/BusinessTwoTone';
import BarChartIcon from '@mui/icons-material/BarChart';
import ReorderIcon from '@mui/icons-material/Reorder';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from '@mui/icons-material/Settings';
import { Theme, useTheme, alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { Fragment, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation, useFetcher } from "react-router-dom";
import { useActions } from "../actions";
import * as ActivityActions from "../actions/activity";
import { Loading } from "../components";
import logo from "../resources/img/hur_logo.png";
import { getAvailableActivities } from "../services/api";
import StartActivityModal from "../components/StartActivityModal";
import { HurLoadingScreen } from "../components";
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { ControlPointDuplicateRounded } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { start } from "repl";



export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		height: "calc(100% - 56px)",
		marginTop: 20,
		[theme.breakpoints.up("sm")]: {
			height: "calc(100% - 64px)",
			marginTop: 64,
		},		
	},
	drawer: {
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up("sm")]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
		backgroundColor: "#46a9db"
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
	},
	toolbar: {
		minHeight: "56px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: '#976d2'
	},
	toolbarText: {
		textAlign: "center",
		fontSize: "1.5rem",
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundImage:
			"url(https://s3-eu-west-1.amazonaws.com/sd7.staattinen.fi/sites/www.hur.fi/files/content/new/cover-equipment.jpg)",
		backgroundPositionX: "center",
		backgroundSize: "cover",
		"&:after": {
			width: "100%",
			height: "100%",
			content: '""',
			display: "block",
			opacity: ".3",
			zIndex: "3",
			position: "absolute",
			background: "#222",
		},
	},
	versionContainer: {
		position: "relative",
		bottom: 0,
		marginBottom: "1rem",
		width: "100%",
	},
	copyright: {
		textAlign: "center",
		color: "white",
	},
	version: {
		textAlign: "center",
		color: "white",
		fontSize: "10px",
	},
	navigationList: {
		color: "white",
		zIndex: 100,
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
		  display: 'flex',
		},
	},
	bottomNavigation: {		
		backgroundColor: "#FFF",
		height: 76,
		width:"100%",			
		alignItems: "center",		
		textAlign: "center",		
	},
	bottomNavigationAction: {				
		minWidth: "50px",		
		color: "#404040", 			
		fontSize: "0.625rem",		
		flexGrow: 1,

	},		
}));

export function Wrapper(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [lastVisit, setLastVisit] = React.useState(null);
	const [isTrainingRunning, setIsTrainingRunning] = React.useState(false);
	const activityActions = useActions(ActivityActions);	
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const currentUser = useSelector((state: RootState) => state.user);
	const unreadMessagesNumber = useSelector((state: RootState) => state.unreadMessagesNumber);
	
	const pathname = window.location.pathname
	const [value, setValue] = React.useState(pathname);
	const { t, i18n } = useTranslation();

	console.log(currentUser);

	const handleDrawerToggle = () => {
		if (isMobile) {
			setMobileOpen(!mobileOpen);
		}
	};

	const handleUpdate = () => {
		window.location.reload();
	};

	const startTraining = () => {		
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	const toggleModal = () => {
		setOpen(!open);
	}

	const handleTrainingButtonClick = () => {
		if(isTrainingRunning) {
			setOpen(true);
		} else {
			setIsTrainingRunning(true);
			setOpen(true);
		}
	}	

	useEffect(() => {
		setValue(location.pathname);
	},[location.pathname])

	console.log("location pathaname", value);
	useEffect(() => {
		setLoading(true);
		async function initData() {
			try {
				const activities = await getAvailableActivities();
				activityActions.loadAvailableActivities(activities);			
			} catch (e) {
				console.log(e);
			}
			setLoading(false);

		}
		initData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	setLoading(true);
	// 	async function initData() {
	// 		try {
	// 			const equipments = await getEquipment();
	// 			equipmentActions.loadEquipments(equipments);
	// 		} catch (e) {
	// 			console.log(e);
	// 		}
	// 		setLoading(false);
	// 	}
	// 	initData();
	// 	eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const serverName = localStorage.getItem("server_name");
	const drawer = (
		<div className={classes.navigationList}>
			<div className={classes.toolbar}>
				<div className={classes.toolbarText}>
					<img alt="logo" src={logo} />
				</div>
			</div>
			{currentUser && <div className={classes.copyright}>{currentUser.first_name} {currentUser.last_name}</div>}
			{serverName != 'null' && <div className={classes.copyright}>({serverName})</div>}
			<Divider />
			<List>
				<ListItem
					button
					key="dashboard"
					onClick={handleDrawerToggle}
					component={RouterLink}
					to="/app/dashboard"
				>
					<ListItemIcon>
						<DashboardIcon htmlColor="#FFF" />
					</ListItemIcon>
					<ListItemText primary={t('left_menu.dashboard')} />
				</ListItem>

				<ListItem
					button
					key="training_sessions"
					onClick={handleDrawerToggle}
					component={RouterLink}
					to="/app/workouts"
				>
					<ListItemIcon>
						<FormatListNumberedIcon htmlColor="#FFF" />
					</ListItemIcon>
					<ListItemText primary={t('left_menu.workouts')} />
				</ListItem>
				<ListItem
					button
					key="statistics"
					onClick={handleDrawerToggle}
					component={RouterLink}
					to="/app/statistics"
				>
					<ListItemIcon>
						<BarChartIcon htmlColor="#FFF" />
					</ListItemIcon>
					<ListItemText primary={t('left_menu.statistics')} />
				</ListItem>				
				<ListItem
					button
					key="settings"
					onClick={handleDrawerToggle}
					component={RouterLink}
					to="/app/settings"
				>
					<ListItemIcon>
						<SettingsIcon htmlColor="#FFF" />
					</ListItemIcon>
					<ListItemText primary={t('left_menu.settings')}  />
				</ListItem>				
			</List>
			<Divider />
			<List>
				<ListItem
					button
					key="Logout"
					onClick={handleDrawerToggle}
					component={RouterLink}
					to="/app/logout"
				>
					<ListItemIcon>
						<PowerSettingsNewIcon htmlColor="#FFF" />
					</ListItemIcon>
					<ListItemText primary={t('left_menu.logout')} />
				</ListItem>
			</List>
			<Divider />
			<div className={classes.versionContainer}>
				<List>
					<ListItem button key="Refresh" onClick={handleUpdate}>
						<ListItemIcon>
							<RefreshIcon htmlColor="#FFF" />
						</ListItemIcon>
						<ListItemText primary={t('left_menu.get_latest_version')} />
					</ListItem>
				</List>
				<Divider />
				<div className={classes.copyright}>
					Copyright &copy; My Hur Gym
				</div>
				<div className={classes.version}>Version 0.3.38b</div>
			</div>
		</div>
	);		
	if (loading) {

		return <HurLoadingScreen />;
	}
    const menuId = 'primary-search-account-menu';
	let toolbarIcon = (
		<IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large">
			<MenuIcon />
		</IconButton>
	);

	const handleProfileMenuOpen = (event) => {
		//setAnchorEl(event.currentTarget);
	};	
	const rootRoutes = ["/app/workouts", "/app/dashboard", "/app/settings", "/app/statistics", "/app/messages","/app/progress","/app/startworkout","/app/planner"];

	if (!rootRoutes.includes(location.pathname)) {
		const handleDrawerBack = () => {
			navigate(-1);
		};
		toolbarIcon = (
			<IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerBack}
                className={classes.menuButton}
                size="large">
				<ArrowBackIcon />
			</IconButton>
		);
	}


	let title = t('dashboard_page.title');
	console.log(location.pathname);
	switch (location.pathname) {
		case "/app/workouts":
			title = t('workouts_page.title');
			break;
		case "/app/settings":
				title = t('settings_page.title');
				break;
		case "/app/messages":
			title = t('messages_page.title');
			break;
		case "/app/statistics":
			title = t('statistics_page.title');
			break;
		case "/app/message/write":
				title = t('message_write_page.title');
				break;
		case "/app/progress":
			title = t('progress_page.title');
				break;
		case "/app/startworkout":
			title = t('startworkout_page.title')
			break;

	}
	// check if matches with session number
	if (location.pathname.match(/\/app\/workouts\/\d+/)) {
		title = t('training_session_page.title'); 
	}
	// check if matches with session number
	if (location.pathname.match(/\/app\/workouts\/\d+\/activity\/\d+/)) {
		title = t('session_activity_page.title'); 
	}

	// check if matches with session number
	if (location.pathname.match(/\/app\/message\/\d+\/.*/)) {
		title = t('message_page.title'); 
	}

	return (
        <Container maxWidth="lg" disableGutters>
			<div className={classes.root}>
				{/* <AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						{toolbarIcon}
						<Typography variant="h6" noWrap>
							{title}
						</Typography>
						<div className={classes.grow} />
						<div className={classes.sectionDesktop}> </div>
						<div>
							<IconButton
                                aria-label="Unread mails"
                                color="inherit"
                                component={RouterLink}
                                to="/app/messages"
                                size="large">
								{unreadMessagesNumber && <Badge badgeContent={unreadMessagesNumber} color="secondary">
									<MailIcon />
								</Badge>
								}
								{!unreadMessagesNumber && <MailIcon />}
							</IconButton>

							<IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                component={RouterLink}
                                to="/app/profile"								
                                color="inherit"
                                size="large">
								<AccountCircle />
							</IconButton>
						</div>						
					</Toolbar>
				</AppBar> */}

				{/* <nav className={classes.drawer} aria-label="mailbox folders">
					<Hidden smUp implementation="css">
						<Drawer
							variant="temporary"
							anchor={
								theme.direction === "rtl" ? "right" : "left"
							}
							open={mobileOpen}
							onClose={handleDrawerToggle}
							classes={{
								paper: classes.drawerPaper,
							}}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
						>
							{drawer}
						</Drawer>
					</Hidden>
					<Hidden smDown implementation="css">
						<Drawer
							classes={{
								paper: classes.drawerPaper,
							}}
							variant="permanent"
							open
						>
							{drawer}
						</Drawer>
					</Hidden>
				</nav> */}				
				{/* sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4} */}
				{props.children}
				<Paper style={{ position: 'fixed', width:"100%", bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "space-evenly", zIndex: 100}}>
					<BottomNavigation
						showLabels
						value={value}
						onChange={(event, newValue) => {
							setValue(newValue);
							navigate(newValue);
						}}
						className={classes.bottomNavigation}
						sx={{
							"& .Mui-selected, .Mui-selected > svg": {
							  color: "#00A0DF",
							  fontWeight: 450
							}
						}}
					
						>
						<BottomNavigationAction className={classes.bottomNavigationAction} label={t('common.bottom_label_home')} component={RouterLink} to="/app/dashboard" value={"/app/dashboard"} icon={value === '/app/dashboard' ? <FilledHome /> : <Home />}/>
						<BottomNavigationAction className={classes.bottomNavigationAction} label={t('common.bottom_label_planner')} component={RouterLink} to="/app/planner" value={"/app/planner"}icon={value === '/app/planner' ? <FilledCalendar /> : <Calendar />} />						
						<BottomNavigationAction 							
							className={classes.bottomNavigationAction}							
							onClick={startTraining}
							icon={value === '/app/startworkout' ? <FilledWorkout /> : <StartTraining />}
							label={t('common.bottom_label_workout')}
							style={{										
								margin: "0 18px",
														
							}}
							component={RouterLink}
							to="/app/startworkout"
							value="/app/startworkout"/>						
						{/* {open ?	(		
						<StartActivityModal open={open} handleClose={handleClose} />
						) : (
							<Fragment />
						) }						 */}
						<BottomNavigationAction className={classes.bottomNavigationAction} label={t('common.bottom_label_progress')} component={RouterLink} to="/app/progress"  value={"/app/progress"} icon={ value === '/app/progress' ? <FilledProgress /> : <Progress />} />
						<BottomNavigationAction className={classes.bottomNavigationAction} label={t('common.bottom_label_profile')} component={RouterLink} to="/app/profile" value={"/app/profile"} icon= {value === '/app/profile' ? <FilledProfile /> : <ProfileIcon />}/>
					</BottomNavigation>
				</Paper>
					

			</div>
		</Container>
    );
}
